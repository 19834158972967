import styled, { keyframes } from "styled-components";

const PageLoaderAnimation = (isLoading) => {
  if (isLoading) {
    return keyframes`
    0% {
      opacity: 1;
    }
    10% {
      opacity: 0.1;
    }
    15% {
      opacity: 0.3;
    }
    25% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    70% {
      opacity: 0.8;
    }
  `;
  } else {
    return keyframes`  0% {
        opacity: 1;
      }
      50% {
        opacity: .85;
      }`;
  }
};

const PageLoader = styled.div`
  position: ${(props) => (props.isLoading ? "absolute" : "static")};
  top: 50%;
  left: 50%;
  margin: 1vh auto 5vh;
  transform: ${(props) => (props.isLoading ? "translate(-50%, -50%)" : "none")};
  width: ${(props) => (props.isLoading ? "35vw" : "10vw")};
  min-width: 150px;
  transition: top 0.6s cubic-bezier(0.2, 0.33, 0.26, 1.13),
    left 0.6s cubic-bezier(0.2, 0.33, 0.26, 1.13),
    width 0.6s cubic-bezier(0.2, 0.33, 0.26, 1.13),
    position 0.6s cubic-bezier(0.2, 0.33, 0.26, 1.13);

  @media (prefers-reduced-motion: no-preference) {
    animation: ${(props) => PageLoaderAnimation(props.isLoading)} infinite
      ${(props) => (props.isLoading ? "1.05s" : "2s")} linear;
  }

  ${(props) =>
    !props.isLoading &&
    `&:hover {
      transition: transform 0.3s;
      transform: scale(1.1);
    }`};
`;

export default PageLoader;
