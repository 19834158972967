export default function formatCurrency(
  number,
  significantDigits = 6,
  currency = "EUR"
) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency,
    maximumSignificantDigits: significantDigits,
  }).format(number);
}
