import React from "react";
import moment from "moment";
import formatCurrency from "./utils/formatCurrency";
import formatNumber from "./utils/formatNumber";
import colors from "./styles/colors";
import Chart from "./Chart";

export default function PriceStats({
  pairs,
  xcmPriceHistory,
  xcmLocked,
  xcmVaulted,
  totalExchangeVolume,
}) {
  const xcmMinPrice =
    xcmPriceHistory && Math.min(...xcmPriceHistory.flat().map((p) => p.l));
  const xcmDelta = pairs?.["XCMEUR"].delta;
  const xcmPositive = xcmDelta > 0;
  const xcmColor = xcmPositive ? colors.green : colors.red;
  const xcmColorTransp = xcmPositive ? colors.greenT : colors.redT;
  const xcmPrice = pairs?.["XCMEUR"].price;
  const xcmVolume = pairs?.["XCMEUR"].volume;

  return (
    <div className="24hStats">
      <p>
        <span style={{ fontWeight: "700" }}>XCM Price: </span>
        {formatCurrency(xcmPrice)}{" "}
        <span style={{ fontWeight: 700, color: xcmColor }}>
          ({Number(xcmDelta * 100).toFixed(2)}%)
        </span>
      </p>
      <p style={{ fontSize: "0.9rem" }}>
        <span style={{ fontWeight: "700" }}>Locked / Vaulted: </span>
        {formatNumber(xcmLocked)} XCM / {formatNumber(xcmVaulted)} XCM
      </p>
      <Chart
        height="35vh"
        config={{
          type: "line",

          // The data for our dataset
          data: {
            labels: xcmPriceHistory
              .flat()
              .map((p) => moment(p.timestamp).format("lll")),
            datasets: [
              {
                label: "XCM Price (€)",
                backgroundColor: xcmColorTransp,
                borderColor: xcmColor,
                pointBackgroundColor: "#fff",
                pointBorderColor: xcmColor,
                pointBorderWidth: 2,
                pointRadius: 4,
                pointHoverRadius: 6,
                pointHitRadius: 35,
                data: xcmPriceHistory.flat().map((p) => p.c),
              },
            ],
          },

          options: {
            maintainAspectRatio: false,
            tooltips: {
              titleFontSize: 14,
              bodyFontSize: 16,
            },
            legend: {
              labels: {
                fontColor: "#999",
                fontSize: 16,
              },
            },
            scales: {
              yAxes: [
                {
                  stacked: true,
                  ticks: {
                    min: xcmMinPrice - xcmMinPrice * 0.01,
                    fontSize: 14,
                    fontColor: "#999",
                  },
                },
              ],
              xAxes: [
                {
                  display: false,
                },
              ],
            },
          },
        }}
      />
      <p>
        <span style={{ fontWeight: "700" }}>XCM/EUR Volume (24h): </span>
        {formatCurrency(xcmVolume)}
      </p>
      <p>
        <span style={{ fontWeight: "700" }}>Exchange volume (24h): </span>
        {formatCurrency(totalExchangeVolume)}
      </p>
    </div>
  );
}
