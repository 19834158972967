import React, { useState, useEffect, useRef, useCallback } from "react";
import formatNumber from "./utils/formatNumber";
import colors from "./styles/colors";
import "./OrderBook.css";

export default function OrderBook({ pairs, getBook }) {
  const [selectedPair, setSelectedPair] = useState("XCMEUR");
  const [orderBook, setOrderBook] = useState(null);
  const [filterText, setFilterText] = useState("XCMEUR");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showCumulativeVolume, setShowCumulativeVolume] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const listRef = useRef(null);
  const asksRef = useRef(null);

  useEffect(() => {
    async function fetchOrderBook() {
      const book = await getBook(selectedPair);
      setOrderBook(book);
    }
    fetchOrderBook();
  }, [selectedPair, getBook]);

  useEffect(() => {
    if (asksRef.current) {
      const asksElement = asksRef.current;
      asksElement.scrollTop = asksElement.scrollHeight;
    }
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
        setFilterText(selectedPair);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedPair]);

  useEffect(() => {
    if (isDropdownOpen && listRef.current) {
      const selectedElement = listRef.current.querySelector(
        `[data-pair="${selectedPair}"]`
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "nearest" });
      }
    }
  }, [isDropdownOpen, selectedPair]);

  const filteredPairs = Object.keys(pairs)
    .sort()
    .filter((pair) => pair.toLowerCase().includes(filterText.toLowerCase()));

  const handleInputFocus = () => {
    setIsDropdownOpen(true);
    setFilterText("");
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
    setIsDropdownOpen(true);
  };

  const toggleCumulativeVolume = () => {
    setShowCumulativeVolume(!showCumulativeVolume);
  };

  const fetchOrderBook = useCallback(async () => {
    setIsRefreshing(true);
    try {
      const book = await getBook(selectedPair);
      setOrderBook(book);
    } catch (error) {
      console.error("Failed to fetch order book:", error);
    } finally {
      setIsRefreshing(false);
    }
  }, [selectedPair, getBook]);

  useEffect(() => {
    fetchOrderBook();
  }, [fetchOrderBook]);

  const handleRefresh = () => {
    fetchOrderBook();
  };

  if (!orderBook) {
    return <div>Loading order book...</div>;
  }

  const processOrderBook = (orders, isAsk) => {
    let cumulative = 0;
    let cumulativeAmountInQuote = 0;

    return orders
      .sort((a, b) => b.price - a.price)
      .map(([price, amount]) => {
        cumulative += Number(amount);
        const amountInQuote = Number(amount) * Number(price); // Calculate amount in terms of the second asset
        cumulativeAmountInQuote += amountInQuote;
        return {
          price: Number(Number(price).toFixed(8)),
          amount: Number(Number(amount).toFixed(8)),
          cumulative: Number(cumulative.toFixed(8)),
          amountInQuote: Number(amountInQuote.toFixed(8)),
          cumulativeAmountInQuote: Number(cumulativeAmountInQuote.toFixed(8)),
        };
      });
  };

  const bids = processOrderBook(Object.entries(orderBook.bid), false);
  const asks = processOrderBook(Object.entries(orderBook.ask), true).reverse();

  const orderBookStyle = {
    marginTop: "5vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "1200px",
    padding: "0 20px",
    boxSizing: "border-box",
    margin: "0 auto",
  };

  const tableContainerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  };

  const tableStyle = {
    width: "100%",
    maxWidth: "500px",
    margin: "0 10px 20px",
  };

  const tableHeaderStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "#282c34",
    zIndex: 1,
  };

  return (
    <div className="orderBook" style={orderBookStyle}>
      <h1>Order Book</h1>
      <div style={{ marginBottom: "2vh", width: "100%", maxWidth: "300px" }}>
        <label
          htmlFor="pairSelect"
          style={{
            display: "block",
            marginBottom: "0.5rem",
            fontWeight: "bold",
          }}
        >
          Select Pair
        </label>
        <div ref={dropdownRef} style={{ position: "relative" }}>
          <input
            id="pairSelect"
            ref={inputRef}
            type="text"
            value={filterText}
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            placeholder="Search pairs..."
            style={{
              width: "100%",
              padding: "8px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              fontSize: "14px",
            }}
          />
          {isDropdownOpen && (
            <ul
              ref={listRef}
              style={{
                width: "100%",
                position: "absolute",
                top: "100%",
                left: 0,
                maxHeight: "200px",
                overflowY: "auto",
                listStyle: "none",
                padding: 0,
                margin: 0,
                border: "1px solid #ccc",
                borderTop: "none",
                borderBottomLeftRadius: "4px",
                borderBottomRightRadius: "4px",
                backgroundColor: "#282c34",
                zIndex: 1000,
                fontSize: "14px",
              }}
            >
              {filteredPairs.map((pair) => (
                <li
                  key={pair}
                  data-pair={pair}
                  onClick={() => {
                    setSelectedPair(pair);
                    setFilterText(pair);
                    setIsDropdownOpen(false);
                  }}
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    backgroundColor: pair === selectedPair ? "#444" : "transparent",
                  }}
                >
                  {pair}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          marginBottom: "2vh",
        }}
      >
        <button
          onClick={toggleCumulativeVolume}
          style={{
            padding: "8px 16px",
            backgroundColor: "#ffb300",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          {showCumulativeVolume ? "Show Regular Volume" : "Show Cumulative Volume"}
        </button>
        <button
          onClick={handleRefresh}
          disabled={isRefreshing}
          style={{
            padding: "8px 16px",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            borderRadius: "4px",
            cursor: isRefreshing ? "not-allowed" : "pointer",
            fontSize: "14px",
            opacity: isRefreshing ? 0.7 : 1,
          }}
        >
          {isRefreshing ? "Refreshing..." : "Refresh"}
        </button>
      </div>
      <div className="tableContainer" style={tableContainerStyle}>
        <div className="tableWrapper" style={tableStyle}>
          <h3>Asks</h3>
          <div
            ref={asksRef}
            className="asks"
            style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}
          >
            <table style={{ width: "100%", tableLayout: "fixed" }}>
              <thead style={tableHeaderStyle}>
                <tr>
                  <th style={{ width: "33%", textAlign: "left" }}>
                    Price {selectedPair.slice(3)}
                  </th>
                  <th style={{ width: "33%", textAlign: "right" }}>
                    Amount {selectedPair.slice(0, 3)}
                  </th>
                  <th style={{ width: "33%", textAlign: "right" }}>
                    Amount {selectedPair.slice(3)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {asks.map((ask) => (
                  <tr className="row" key={ask.price}>
                    <td style={{ color: colors.red, textAlign: "left" }}>
                      {formatNumber(ask.price, 5)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatNumber(
                        showCumulativeVolume ? ask.cumulative : ask.amount,
                        3
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatNumber(
                        showCumulativeVolume
                          ? ask.cumulativeAmountInQuote
                          : ask.amountInQuote,
                        3
                      )}
                    </td>{" "}
                    {/* Display amount in second asset */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="tableWrapper" style={tableStyle}>
          <h3>Bids</h3>
          <div
            style={{ maxHeight: "400px", overflowY: "auto", overflowX: "hidden" }}
          >
            <table style={{ width: "100%", tableLayout: "fixed" }}>
              <thead style={tableHeaderStyle}>
                <tr>
                  <th style={{ width: "33%", textAlign: "left" }}>
                    Price {selectedPair.slice(3)}
                  </th>
                  <th style={{ width: "33%", textAlign: "right" }}>
                    Amount {selectedPair.slice(0, 3)}
                  </th>
                  <th style={{ width: "33%", textAlign: "right" }}>
                    Amount {selectedPair.slice(3)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {bids.map((bid) => (
                  <tr className="row" key={bid.price}>
                    <td style={{ color: colors.green, textAlign: "left" }}>
                      {formatNumber(bid.price, 5)}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatNumber(
                        showCumulativeVolume ? bid.cumulative : bid.amount,
                        3
                      )}
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {formatNumber(
                        showCumulativeVolume
                          ? bid.cumulativeAmountInQuote
                          : bid.amountInQuote,
                        3
                      )}
                    </td>{" "}
                    {/* Display amount in second asset */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
