import React from "react";
import logo from "./assets/cm-logo.svg";
import PageLoader from "./styles/PageLoader";

export default function Header({ pageLoading }) {
  return (
    <PageLoader isLoading={pageLoading}>
      <a href="https://xcmprice.com/goxcm" target="_blank">
        <img src={logo} alt="Coinmetro logo" />
      </a>
    </PageLoader>
  );
}
