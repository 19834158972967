import styled from "styled-components";

const MainContainer = styled.div`
  background-color: #282c34;
  padding: 1vh 5vw;
  min-height: 100vh;
  text-align: center;
  font-size: calc(10px + 1.25vmin);
  color: white;
  box-sizing: border-box;

  & a {
    color: #fcd600;
    /* color: rgb(224, 86, 86); */
    text-decoration: none;
    /* border-bottom: 4px solid #9728ff; */
    box-shadow: none;

    &:hover {
      box-shadow: 0px 6px 4px -2px #b66ff8;
    }
  }
`;

export default MainContainer;
