import React, { useState, useEffect, useLayoutEffect } from "react";
import { Route, HashRouter } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
import MainContainer from "./styles/MainContainer";
import PriceStats from "./PriceStats";
import VolumeShare from "./VolumeShare";
import CryptoSentiment from "./CryptoSentiment";
import Depth from "./Depth";
import Header from "./Header";
import Footer from "./Footer";
import OrderBook from "./OrderBook";

if (process.env.NODE_ENV === "production") {
  ReactGA.initialize("UA-113481430-1");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function App() {
  const [pageLoading, setPageLoading] = useState(true);
  const [pairs, setPairs] = useState(null);
  const [xcmDepth, setXcmDepth] = useState(null);
  const [xcmPriceHistory, setXcmPriceHistory] = useState(null);
  const [xcmLocked, setXcmLocked] = useState(null);
  const [xcmVaulted, setXcmVaulted] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(true);

  const totalExchangeVolume = Object.values(pairs || {}).reduce((acc, pair) => {
    return acc + pair.volume;
  }, 0);

  useLayoutEffect(() => {
    if (shouldFetch) {
      (async () => {
        setShouldFetch(false);
        const data = await getData();
        if (
          data.pairMap &&
          data.history &&
          data?.depth &&
          data?.locked &&
          data?.vaulted
        ) {
          setPairs(data?.pairMap);
          setXcmPriceHistory(data?.history);
          setXcmDepth(data?.depth);
          setXcmLocked(data?.locked);
          setXcmVaulted(data?.vaulted);
          setPageLoading(false);
        }
      })();
    }
  }, [shouldFetch]);

  useEffect(() => {
    const fetchInterval = setInterval(
      () => setShouldFetch(true),
      pageLoading ? 5000 : 1000 * 60 * 1
    );
    return () => clearInterval(fetchInterval);
  }, [pageLoading]);

  return (
    <MainContainer>
      <Header pageLoading={pageLoading} />
      {!pageLoading && (
        <>
          <HashRouter>
            <Route exact path="/">
              <PriceStats
                pairs={pairs}
                xcmPriceHistory={xcmPriceHistory}
                xcmLocked={xcmLocked}
                xcmVaulted={xcmVaulted}
                totalExchangeVolume={totalExchangeVolume}
              />
              <OrderBook pairs={pairs} getBook={getBook} />
              <VolumeShare pairs={pairs} totalExchangeVolume={totalExchangeVolume} />
              <CryptoSentiment pairs={pairs} />
              <Depth depth={xcmDepth} />
            </Route>

            <Route
              exact
              path="/sentiment"
              component={() => <CryptoSentiment pairs={pairs} />}
            />
          </HashRouter>
        </>
      )}
      <Footer />
    </MainContainer>
  );
}

export default App;

async function getData() {
  try {
    const pairMap = await getPairMap();
    const history = await getHistory();
    const depth = await getDepth();
    const locked = await getLockedAmount();
    const vaulted = await getVaultedAmount();
    return { pairMap, history, depth, locked, vaulted };
  } catch (e) {
    console.error(`Couldn't get stats data: \n${e.name}: ${e.message}`);
  }
}

async function getPairs() {
  try {
    const { data: pairs } = await axios.get(
      "https://relay.xcmprice.com/?url=https://api.coinmetro.com/exchange/prices"
    );
    return pairs;
  } catch (e) {
    console.error(`Couldn't get pairs data. \n${e.name}: ${e.message}`);
  }
}

async function getHistory(pair = "XCMEUR") {
  try {
    const {
      data: { candleHistory: history },
    } = await axios.get(
      `https://relay.xcmprice.com/?url=https://api.coinmetro.com/exchange/candles/${pair}/1800000/${
        Date.now() - 24 * 60 * 60 * 1000
      }/${Date.now()}`
    );
    return history;
  } catch (e) {
    console.error(`Couldn't get ${pair} candle history: \n${e.name}: ${e.message}`);
  }
}

async function getBook(pair) {
  try {
    const {
      data: { book },
    } = await axios.get(
      `https://relay.xcmprice.com/?url=https://api.coinmetro.com/exchange/book/${pair}`
    );
    return book;
  } catch (e) {
    console.error(`Couldn't get ${pair} book. \n${e.name}: ${e.message}`);
  }
}

async function getRates() {
  try {
    // get eur rates
    const { data: rates } = await axios.get(
      `https://relay.xcmprice.com/?url=https://api.coinmetro.com/rates`
    );
    return rates;
  } catch (e) {
    console.error(`Couldn't get exchange rates. \n${e.name}: ${e.message}`);
  }
}

async function getLockedAmount() {
  try {
    // get eur rates
    const { data: locked } = await axios.get(
      `https://relay.xcmprice.com/?url=https://api.coinmetro.com/xcm/locked-platform`
    );
    return locked;
  } catch (e) {
    console.error(`Couldn't get locked XCM amount. \n${e.name}: ${e.message}`);
  }
}

async function getVaultedAmount() {
  try {
    // get eur rates
    const {
      data: { allTime: vaulted },
    } = await axios.get(
      `https://relay.xcmprice.com/?url=https://api.coinmetro.com/xcm/locked-wallet`
    );
    return vaulted;
  } catch (e) {
    console.error(`Couldn't get vaulted XCM amount. \n${e.name}: ${e.message}`);
  }
}

async function getPairMap() {
  try {
    const pairs = await getPairs();
    const eurRates = await getRates();
    const pairNames = pairs.latestPrices.map((data) => data.pair);
    const pairMap = pairNames.reduce((acc, pn, i) => {
      let [denominatorCurrency] = Object.keys(eurRates).filter((currencyName) =>
        pn.endsWith(currencyName)
      );
      if(pn.endsWith('IGN') || denominatorCurrency === undefined){
        denominatorCurrency = "IGN€"
      }
      const eurRate = eurRates[denominatorCurrency];
      return {
        ...acc,
        [pn]: {
          pairName: pn,
          volume: pairs["24hInfo"][i].v * pairs["latestPrices"][i].price * eurRate,
          price: pairs["latestPrices"][i].price,
          high: pairs["24hInfo"][i].h,
          low: pairs["24hInfo"][i].l,
          delta: pairs["24hInfo"][i].delta,
          sentimentData: pairs["24hInfo"][i].sentimentData,
        },
      };
    }, {});
    return pairMap;
  } catch (e) {
    console.error(`Couldn't get pairs map \n${e.name}: ${e.message}`);
  }
}

async function getDepth(pair = "XCMEUR") {
  try {
    const book = await getBook(pair);

    const sortedAsk = calcCumulativeVol(
      false,
      Object.keys(book.ask)
        .map((a) => {
          return { vol: book.ask[a], val: Number(a) };
        })
        .sort((a, b) => {
          if (a?.val > b?.val) {
            return 1;
          } else if (a?.val < b?.val) {
            return -1;
          } else {
            return 0;
          }
        })
    );

    const sortedBid = calcCumulativeVol(
      true,
      Object.keys(book.bid)
        .map((a) => {
          return {
            vol: Number(book.bid[a]),
            val: Number(a),
          };
        })
        .sort((a, b) => {
          if (a?.val > b?.val) {
            return 1;
          } else if (a?.val < b?.val) {
            return -1;
          } else {
            return 0;
          }
        })
    );
    return { ask: sortedAsk, bid: sortedBid };
  } catch (e) {
    console.error(`Couldn't get depth: \n${e.name}: ${e.message}`);
  }
}

function calcCumulativeVol(descending, list) {
  let acc = 0;
  let result = [];
  if (descending) {
    result = list
      .reverse()
      .map((c) => {
        const totalVol = acc + c.vol;
        acc += c.vol;
        return { ...c, totalVol: totalVol };
      })
      .reverse();
  } else {
    result = list.map((c) => {
      const totalVol = acc + c.vol;
      acc += c.vol;
      return { ...c, totalVol: totalVol };
    });
  }
  return result;
}
