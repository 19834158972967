import React, { useRef, useEffect } from "react";
import Chart from "chart.js";
import styled from "styled-components";
import "chartjs-gauge";

const ChartContainer = styled.div`
  position: relative;
  height: ${(props) => props.height || "auto"};
  width: ${(props) => props.width || "auto"};
  min-height: 150px;
`;

export default function ChartComponent({ width, height, config }) {
  const chart = useRef();
  const canvasRef = useRef();
  useEffect(() => {
    if (!chart.current) {
      chart.current = new Chart(canvasRef.current, config);
    }
  }, [config]);
  return (
    <ChartContainer width={width} height={height}>
      <canvas ref={canvasRef} />
    </ChartContainer>
  );
}
